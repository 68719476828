* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Proxima Nova' 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--container-color);
}

*::-webkit-scrollbar {
	width: 4px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
	background: #f1f1f1; /* Background color of the track */
	border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
	background: #888; /* Color of the scrollbar thumb */
	border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
	background: #555; /* Color of the scrollbar thumb on hover */
}

@font-face {
	font-family: 'Segoe UI';
	src: url('./assets/fonts/segoeui.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Segoe';
	font-weight: 700;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/642d58629675220e9d48a0bc/fonts/segoe-ui.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Segoe UI';
	font-weight: 600;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/63485fb88ee4feae84ab3dea/fonts/seguisb.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Segoe UI';
	font-weight: 400;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/632d42142e19a92ebeed82db/fonts/segoe-ui.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Segoe UI';
	font-weight: 200;
	src: url('https://anima-uploads.s3.amazonaws.com/5c46fd57ebf979000bb37381/segoeuisl.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Segoe UI';
	font-weight: 300;
	src: url('https://anima-uploads.s3.amazonaws.com/5c46fd57ebf979000bb37381/segoeuil.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Segoe UI';
	font-weight: 800;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/61f56b25eb1e810fc50e7be4/fonts/seguibl.ttf')
		format('truetype');
}

#root {
	width: 100vw;
	height: 100vh;
}

@primary-color: #0a0523;@secondary-color: #6C5DD3;@ui-red: #E53E3E;@ui-black: #111010;