.layout-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.layout-main-action-wrapper {
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

@media (max-width: 1280px) {
	.layout-main-action-wrapper {
		width: 100%;
	}
}

@primary-color: #0a0523;@secondary-color: #6C5DD3;@ui-red: #E53E3E;@ui-black: #111010;