:root {
	--container-color: #dddddd;
	--header-height: 80px;
	--header-color: #131314;
	--header-tabs-color: #ebeff4;
	--common-blue-color: #0079ff;
	--nested-container-color: #f0f0f0;
	--common-item-color: #f8f8f8;
	--common-item-hover-color: #ebeff4;
	--common-select-background-color: #f0f0f0;
}
