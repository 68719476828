@import url('./__variables.less');

.container {
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100vh;
	display: flex;
	background-color: var(--container-color);
	overflow-x: hidden;
}

.ant-tab-custom-styles-borderless {
	.ant-tabs-nav {
		margin: 0;
	}
	.ant-tabs-nav::before {
		display: none !important;
	}
	.ant-tabs-tab {
		margin: 0 !important;
	}
	.ant-tabs-nav-list {
		gap: 10px;
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: var(--common-blue-color) !important;
		}
	}
	.ant-tabs-tab-btn {
		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: var(--single-bet-row-title);
		text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
	}
	.ant-tabs-tab {
		color: var(--header-tabs-color);
	}
}

.popup-input-border {
	border: 1px solid #d1d1d1 !important;
	border-radius: 2px;
}

.filter-fields-text {
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	font-family: 'Segoe UI';
}

.page-main-layout {
	// display: flex;
	// flex-direction: column;
	// justify-content: space-between;
	// min-height: calc(100vh - 91px);
	overflow-y: scroll;
	max-height: calc(100vh - 170px);
	height: calc(100vh - 170px);
}

.align-center {
	display: flex;
	align-items: center;
}

.live-icon {
	width: 31px;
	height: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	leading-trim: both;
	text-edge: cap;
	font-family: 'Segoe UI';
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	border-radius: 2px;
	margin-right: 2px;
}
.bet-status-button {
	&__WON,
	&__verify {
		background-color: #0d8f0e;
	}
	&__LOST,
	&__decline {
		background-color: #ff4747;
	}
	&__ACCEPTED,
	&__unsettled {
		background-color: #ffa800;
	}

	&__CANCELED {
		background-color: #960f0f;
	}

	&__pending,
	&__VOID {
		background-color: #9da0a6;
	}
}
